export const routes = [
    {
        path: '/departments',
        name: 'departments.browse',
        component: () => import(/* webpackChunkName: "DepartmentsBrowse" */ '@/views/app/DepartmentsBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/departments/create',
        name: 'departments.create',
        component: () => import(/* webpackChunkName: "DepartmentsActions" */ '@/views/app/DepartmentsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/departments/:id/edit',
        name: 'departments.edit',
        component: () => import(/* webpackChunkName: "DepartmentsActions" */ '@/views/app/DepartmentsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/departments/:id/delete',
        name: 'departments.delete',
        component: () => import(/* webpackChunkName: "DepartmentsActions" */ '@/views/app/DepartmentsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
]